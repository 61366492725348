
import request from "@/utils/request";
//加密货币账号
export const cryptoCoinList = (data) =>
  request({
    url: "/user/wallet/cryptoCoinList",
    method: "post",
    data,
  });
//卖加密货币
export const saleCoin = (data) =>
  request({
    url: "/user/wallet/saleCoin",
    method: "post",
    data,
  });
//买加密货币
export const buyCoin = (data) =>
  request({
    url: "/user/wallet/buyCoin",
    method: "post",
    data,
  });
///添加加密货币钱包
  export const addCoinWallet = (data) =>
    request({
      url: "/user/wallet/addCoinWallet",
      method: "post",
      data,
    });
    
//删除已有钱包
export const deleteWallet = (data) =>
    request({
      url: "/user/wallet/deleteWallet",
      method: "post",
      data,
    });
//加密货币币种余额
export const coinBalance = (data) =>
request({
  url: "/user/wallet/coinBalance",
  method: "post",
  data,
});
//加密货币发送
export const coinSend = (data) =>
request({
  url: "/user/wallet/coinSend",
  method: "post",
  data,
});

//加密货币接收/
export const reciveCoin = (data) =>
request({
  url: "/user/wallet/reciveCoin",
  method: "post",
  data,
});

//充值
export const recharge = (data) =>
request({
  url: "/user/wallet/recharge",
  method: "post",
  data,
});