<template>
	<el-card class="box-card">
		<el-row>
			<el-col :span="24">
				<Header/>
				<topHeader />
				<Headers @recharge="handleClickBtn" />
			<!-- 	<div class="btn-list">
					<el-button @click="handleClickBtn('recharge')">{{
					  translateTitle("充值加密账号")
					}}</el-button>
				</div> -->
				<div v-if="type == 1" class="table-detali mt20">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane :label="translateTitle('银行交易明细')" name="first"></el-tab-pane>
					</el-tabs>
					<div>
						{{ translateTitle("排序") }}
						<el-date-picker v-model="value1" type="datetimerange" range-separator="-"
							:start-placeholder="translateTitle('开始日期')" :end-placeholder="translateTitle('结束日期')"
							value-format="yyyy-MM-dd" @change="changeData">
						</el-date-picker>
					</div>
				</div>
				<div v-else-if="type == 2" class="table-detali">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane :label="translateTitle('个人信息')" name="first"></el-tab-pane>
						<el-tab-pane :label="translateTitle('地址信息')" name="second"></el-tab-pane>
					</el-tabs>
					<div>
						<el-button type="warning" :loading="loading" @click="handcreateMccard">{{
              translateTitle("创建银行账户")
            }}</el-button>
						<el-button type="warning" :loading="loading1" @click="submitForm('ruleForm')">{{
              translateTitle("保存更改")
            }}</el-button>
					</div>
				</div>
				<el-table class="custom-table" v-loading="tableLoading" stripe  v-if="type == '1'"
					:data="tableData" style="width: 100%">
					<el-table-column prop="coinName" min-width="100" :label="translateTitle('币种')"
						show-overflow-tooltip>
					</el-table-column>
					<!-- <el-table-column prop="address" label="状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <span :class="true ? 'succ' : 'err'">完成</span>
            </template>
          </el-table-column> -->
					<el-table-column v-if="activeName == 'first'" prop="amount" min-width="150"
						:label="translateTitle('交易金额')" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="balance" min-width="100" :label="translateTitle('资产')" show-overflow-tooltip>
					</el-table-column>
					<!-- <el-table-column
            prop="coinLogo"
            min-width="100"
            :label="translateTitle('币种图标')"
            show-overflow-tooltip
          >
          </el-table-column> -->
					<el-table-column prop="datesStr" min-width="150" :label="translateTitle('交易日期')"
						show-overflow-tooltip>
					</el-table-column>
					<el-table-column v-if="activeName == 'first'" prop="description" min-width="120"
						:label="translateTitle('描述')" show-overflow-tooltip>
					</el-table-column>
					<el-table-column v-if="activeName == 'second'" prop="sourceWalletAddress" min-width="150"
						:label="translateTitle('来源地址')" show-overflow-tooltip>
					</el-table-column>
					<el-table-column v-if="activeName == 'second'" prop="targetWalletAddress" min-width="150"
						:label="translateTitle('到账地址')" show-overflow-tooltip>
					</el-table-column>
					<el-table-column v-if="activeName == 'second'" prop="transactionType" min-width="150"
						:label="translateTitle('交易类型')" show-overflow-tooltip>
					</el-table-column>
					<el-table-column v-if="activeName == 'second'" prop="walletAddress" min-width="150"
						:label="translateTitle('钱包地址')" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="address" :label="translateTitle('操作')" width="100">
						<template v-slot="scope">
							<div class="disal">
								<el-button type="text" size="mini" style="color: #786234;"
									@click="handleClickBtn('detail', scope.row)">{{translateTitle('详情')}}</el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<Pagination v-if="type == '1'" :page.sync="page" :limit.sync="pageSize" :total="total"
					@pagination="getTableData" />
				<!-- <el-form
          v-if="type == '2' && activeName == 'first'"
          :model="ruleForm"
          :rules="rules"
          label-position="top"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm person-form"
        >
          <el-row class="edress-content" :gutter="50">

            <el-col :span="12">
              <el-form-item :label="translateTitle('国籍')" prop="nationality">
                <el-select
                  v-model="ruleForm.nationality"
                  filterable
                  clearable
                  :placeholder="translateTitle('输入国籍')"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.alpha2"
                    :label="item.name"
                    :value="item.alpha2"
                  >
                    <div class="disal">
                    <img
                      :src="item.logo"
                      width="24"
                      height="24"
                    />
                    <span class="select-item">{{ item.name }}</span>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="translateTitle('标题')" prop="title">
                <el-select
                  v-model="ruleForm.title"
                  :placeholder="translateTitle('请选择')"
                >
                  <el-option
                    v-for="item in options3"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="translateTitle('名字')" prop="lastName">
                <el-input
                  v-model="ruleForm.lastName"
                  :placeholder="translateTitle('输入姓名')"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="translateTitle('用户姓氏')"
                prop="firstName"
              >
                <el-input
                  v-model="ruleForm.firstName"
                  :placeholder="translateTitle('输入用户姓氏')"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="translateTitle('中间名字')" prop="middleName">
                <el-input
                  v-model="ruleForm.middleName"
                  :placeholder="translateTitle('输入中间姓名')"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="translateTitle('性别')" prop="gender">
                <el-select
                  v-model="ruleForm.gender"
                  :placeholder="translateTitle('请选择')"
                >
                  <el-option
                    v-for="item in options2"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="translateTitle('出生日期')"
                prop="dateOfBirth"
              >
                <el-date-picker
                  v-model="ruleForm.dateOfBirth"
                  clearable
                  format="yyyy-MM-dd"
                  :placeholder="translateTitle('选择日期')"
                  type="date"
                  value-format="yyyy-MM-dd"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="translateTitle('移动国家代码')"
                prop="phoneCountryCode"
              >
                <el-select
                  v-model="ruleForm.phoneCountryCode"
                  filterable
                  clearable
                  :placeholder="translateTitle('输入国籍')"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.phoneCode"
                    :label="item.name"
                    :value="item.phoneCode"
                  >
                    <div class="disal">
                    <img
                      :src="item.logo"
                      width="24"
                      height="24"
                    />
                    <span class="select-item">{{ item.name }}</span>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            
            <el-col :span="12">
              <el-form-item :label="translateTitle('电子邮件')" prop="email">
                <el-input
                  v-model="ruleForm.email"
                  :placeholder="translateTitle('输入电子邮件')"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="translateTitle('电话号码')" prop="phone">
                <el-input
                  v-model="ruleForm.phone"
                  :placeholder="translateTitle('输入电话号码')"
                ></el-input>
              </el-form-item>
            </el-col>
            
            <el-col :span="12">
              <el-form-item :label="translateTitle('证件类型')" prop="idType">
                <el-select
                  v-model="ruleForm.idType"
                  :placeholder="translateTitle('请选择')"
                >
                  <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="translateTitle('证件号码')" prop="idNumber">
                <el-input
                  v-model="ruleForm.idNumber"
                  :placeholder="translateTitle('输入证件号码')"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form> -->
				<!-- <el-form
          v-if="type == '2' && activeName == 'second'"
          :model="ruleForm"
          :rules="rules"
          label-position="top"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm person-form"
        >
          <el-row v-if="showAddress" class="edress-content" :gutter="50">
            <el-col :span="24">
              <el-form-item
                :label="translateTitle('地址提示')"
                prop="deliveryAddress"
              >
                <el-input
                  v-model="ruleForm.deliveryAddress"
                  :placeholder="translateTitle('输入地址')"
                >
                  <template slot="append">
                    <img
                      :src="require('@/assets/imgs/icon_copy.png')"
                      @click="onCopy(ruleForm.deliveryAddress)"
                    />
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="translateTitle('城市')" prop='deliveryCity'>
                <el-input
                  v-model="ruleForm.deliveryCity"
                  :placeholder="translateTitle('输入姓名')"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="translateTitle('国家')" prop="deliveryCountry">
                <el-select
                  v-model="ruleForm.deliveryCountry"
                  :placeholder="translateTitle('请选择')"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.alpha2"
                    :label="item.name"
                    :value="item.alpha2"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="translateTitle('邮政编码')" prop="deliveryZipCode">
                <el-input
                  v-model="ruleForm.deliveryZipCode"
                  :placeholder="translateTitle('请输入邮政编码')"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div v-if="!showAddress">
            {{ translateTitle("居住地址") }}*
            <div class="edress-content edress-content1">
              <div>{{ translateTitle("暂无内容") }}</div>
              <div class="editbtn" @click="editAddress(0)">
                {{ translateTitle("编辑") }}
              </div>
            </div>
          </div>
          <el-row v-if="showAddress1" class="edress-content" :gutter="50">
            <el-col :span="24">
              <el-form-item
                :label="translateTitle('地址提示')"
                prop="billingAddress"
              >
                <el-input
                  v-model="ruleForm.billingAddress"
                  :placeholder="translateTitle('输入地址')"
                >
                  <template slot="append">
                    <img
                      :src="require('@/assets/imgs/icon_copy.png')"
                      @click="onCopy(ruleForm.billingAddress)"
                    />
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="translateTitle('城市')" prop="billingCity">
                <el-input
                  v-model="ruleForm.billingCity"
                  :placeholder="translateTitle('请输入城市')"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="translateTitle('国家')" prop="billingCountry">
                <el-select
                  v-model="ruleForm.billingCountry"
                  :placeholder="translateTitle('请选择国家')"
                  clearable
                >
                  <el-option
                    v-for="item in options"
                    :key="item.alpha2"
                    :label="item.name"
                    :value="item.alpha2"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="translateTitle('邮政编码')" prop="billingZipCode">
                <el-input
                  v-model="ruleForm.billingZipCode"
                  :placeholder="translateTitle('请输入邮政编码')"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div v-if="!showAddress1">
            {{ translateTitle("账单地址") }}*
            <div class="edress-content edress-content1">
              <div>{{ translateTitle("暂无内容") }}</div>
              <div class="editbtn" @click="editAddress(1)">
                {{ translateTitle("编辑") }}
              </div>
            </div>
          </div>
        </el-form> -->
				<!-- <el-table v-loading="tableLoading" v-if="type == '3'" :data="tableData" style="width: 100%">
          <el-table-column
            prop="name"
            :label="translateTitle('姓名')"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="email"
            :label="translateTitle('邮箱')"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="address"
            :label="translateTitle('操作')"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-button type="warning" @click="bankTransfer(scope.row)">{{
                translateTitle("转账")
              }}</el-button>
              <img
                @click="deleteContact(scope.row)"
                src="../../assets/imgs/icon_yichu.png"
              />
            </template>
          </el-table-column>
        </el-table> -->
				<!-- 分页 -->
				<!-- <Pagination
          v-if="type == '3'"
          :page.sync="contactQuery.page"
          :limit.sync="contactQuery.pageSize"
          :total="contactQuery.total"
          @pagination="getContact"
        /> -->
			</el-col>
		</el-row>
		<!-- 详情 -->
		<DialogPop width="700px" :title="translateTitle('详情')" :visible="visibleDetail" :closeBtn="false"
			:sureBtn="false" @onClose="onCloseDetail">
			<el-descriptions class="change-descriptions" title="" :column="1">
				<el-descriptions-item :label="translateTitle('币种')">{{ detailData.coinName }}</el-descriptions-item>
				<el-descriptions-item  :label="translateTitle('交易金额')"
				><span>{{ detailData.amount }}</span></el-descriptions-item>
				<el-descriptions-item :label="translateTitle('资产')">{{ detailData.balance }}</el-descriptions-item>
				<el-descriptions-item  :label="translateTitle('交易时间')">{{ detailData.datesStr }}</el-descriptions-item>
				<el-descriptions-item :label="translateTitle('描述')">{{ detailData.description }}</el-descriptions-item>
			</el-descriptions>
		</DialogPop>
		<!-- 添加联系人 -->
		<DialogPop width="500px" :title="translateTitle('添加联系人')" :visible="visible" :closeBtn="false"
			:btnloading="btnloading" :sureText="translateTitle('确定')" @onClose="onClose" @onSure="onSure('ruleForm1')">
			<div class="change-form">
				<el-form label-position="top" :model="ruleForm1" status-icon :rules="rules" ref="ruleForm1"
					label-width="100px" class="demo-ruleForm" hide-required-asterisk>
					<el-form-item :label="translateTitle('姓名')" prop="name">
						<el-input v-model="ruleForm1.name" clearable :placeholder="translateTitle('请输入姓名')"></el-input>
					</el-form-item>
					<el-form-item :label="translateTitle('邮箱')" prop="email">
						<el-input v-model="ruleForm1.email" clearable
							:placeholder="translateTitle('请输入账户邮箱')"></el-input>
					</el-form-item>
				</el-form>
			</div>
		</DialogPop>
		<!-- 转账 -->
		<DialogPop width="700px" :title="translateTitle('转账方式')" :visible="visible1" :closeBtn="false"
			:btnloading="btnloading" :closeText="translateTitle('取消')" :sureText="translateTitle('转账')"
			@onClose="onClose1" @onSure="onSure1('ruleForm2')">
			<div class="custum-dialog type">
				<div class="img" :class="isCheck == '1' ? 'active' : ''" @click="checkType(1)">
					<img :src="img4" width="120" class="mb15" />
					<p>{{ translateTitle("银行账户") }}</p>
				</div>
				<div class="img" :class="isCheck == '2' ? 'active' : ''" @click="checkType(2)">
					<img :src="img5" width="130" class="mb10" />
					<p>{{ translateTitle("加密账户") }}</p>
				</div>
			</div>
			<div class="change-form">
				<el-form label-position="top" :model="ruleForm2" :rules="rules1" ref="ruleForm2" label-width="100px"
					class="demo-ruleForm" hide-required-asterisk>
					<el-form-item :label="translateTitle('数量')" prop="amount">
						<el-input :placeholder="translateTitle('请输入数量')" size="large" v-model="ruleForm2.amount"
							class="input-with-select">
							<el-select class="svg-container" v-if="isCheck == '2'" v-model="ruleForm2.coinName"
								slot="append" :placeholder="translateTitle('请选择')" @change="changeCoinName">
								<template #prefix>
									<span class="flex">
										<img :src="choiceCoin.coinLogo" width="24" height="24" />
									</span>
								</template>
								<el-option v-for="(item, index) in coinNameData" :key="item.coinName"
									:label="item.coinName" :value="item.coinName">
									<div class="disal">
										<img :src="item.coinLogo" width="24" height="24" />
										<span class="select-item">{{ item.coinName }}</span>
									</div>
								</el-option>
							</el-select>
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="">
						<div class="line3">
							<span class="left">{{ translateTitle("可用余额") }}</span>
							<span class="right">＄{{ BalanceCoinNam }}</span>
						</div>
						<div class="line3">
							<span class="left">{{ translateTitle("实际到账") }}</span>
							<span class="right">＄{{ ruleForm.amount || 0 }}</span>
						</div>
					</el-form-item>
					<el-form-item v-if="isCheck == '2'" :label="translateTitle('提币网络')" prop="coinNetWork">
						<el-select style="width: 100%" class="svg-container" v-model="ruleForm2.coinLink"
							:placeholder="translateTitle('请选择')" @change="changeCoinLink">
							<template #prefix>
								<span class="flex">
									<img :src="require('@/assets/imgs/icon_btc.png')" width="24" height="24" />
								</span>
							</template>
							<el-option v-for="item in CoinLinkOption" :key="item.coinLink" :label="item.coinLink"
								:value="item.coinLink">
								<div class="disal">
									<span class="select-item">{{ item.coinLink }}</span>
								</div>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="translateTitle('邮箱')" prop="email">
						<el-input size="large" :placeholder="translateTitle('输入收件人邮箱')" v-model="ruleForm2.email"
							clearable>
						</el-input>
					</el-form-item>
				</el-form>
			</div>
		</DialogPop>
		<!-- 充值弹窗 -->
		<RechargeDiaolg v-if="rechargeVisible" v-model="rechargeVisible" @visibleEvent="visibleEvent" />
	</el-card>
</template>

<script>
	import Headers from "../../components/accountHeader/accounIndex";
	import topHeader from "../../components/topHeader/index"
	import local from "@/utils/local";
	import {
		coinNames,
		getBalanceCoinNam,
		userBankBalance,
		coinLink,
		coinBalanceUSD,
	} from "@/api/index";
	import {
		addContact,
		contactPage,
		deleteDontact,
		getNational,
		getUserinfo,
		coin_transfer,
		bank_transfer,
		createMccard,
		bankAccountStatement,
		cryptoStatement,
		addUserInfo,
		getIdTypes,
	} from "@/api/accountManage";
	import {
		translateTitle
	} from "@/utils/i18n";
    import RechargeDiaolg from "./components/rechargeDiaolg";
	export default {
		components: {
			Headers,
			topHeader,
			RechargeDiaolg
		},
		data() {
			const validateName = (rule, value, callback) => {
				var xreg = /^[a-zA-Z]*$/
				if (!xreg.test(value)) {
					callback(new Error(this.translateTitle("请输入正确格式")))
				} else {
					callback()
				}
			}
			const validateAddress = (rule, value, callback) => {
				var xreg = /^[a-zA-Z0-9{\s}@,._-]*$/
				if (!xreg.test(value)) {
					callback(new Error(this.translateTitle("请输入正确格式")))
				} else {
					callback()
				}
			}
			return {
				value1: false,
				img4: require("@/assets/imgs/icon_yinhangzhanghu @2x.png"),
				img5: require("@/assets/imgs/icon_jiamizhanghu @2x.png"),
				value: "",
				total: 0,
				type: 1,
				page: 1,
				pageSize: 10,
				visibleDetail: false,
				detailData: "",
				showAddress: false,
				showAddress1: false,
				visible: false,
				visible1: false,
				loading: false,
				loading1: false,
				rechargeVisible:false,
				tableData: [],
				tableLoading: false,
				startDate: "",
				endDate: "",
				options1: [],
				options2: ["M", "F", "O"],
				options3: ["Mr", "Mrs", "Ms"],
				options: [],
				activeName: "first",
				ruleForm: {
					billingAddress: "",
					email: "33@qq.com",
					billingCity: "",
					billingCountry: "",
					billingZipCode: "",
					deliveryAddress: "",
					deliveryCity: "",
					deliveryCountry: "",
					deliveryZipCode: "",
					dateOfBirth: "",
					firstName: "",
					gender: "",
					idNumber: "",
					idType: "",
					lastName: "",
					middleName: "",
					nationality: "",
					phone: "",
					phoneCountryCode: "",
					title: "",
				},
				ruleForm1: {
					name: "",
					email: "",
				},
				contactQuery: {
					total: 0,
					page: 0,
					pageSize: 10,
				},
				coinNameData: [],
				BalanceCoinNam: "",
				rules: {
					name: [{
						required: true,
						message: this.translateTitle("请输入姓名"),
						trigger: "change",
					}, ],
					email: [{
						required: true,
						message: this.translateTitle("请输入邮箱"),
						trigger: "change",
					}, ],
					phone: [{
						required: true,
						message: this.translateTitle("请输入电话号码"),
						trigger: "change",
					}, ],
					title: [{
						required: true,
						message: this.translateTitle("请选择标题"),
						trigger: "change",
					}, ],
					phoneCountryCode: [{
						required: true,
						message: this.translateTitle("请输入移动国家代码"),
						trigger: "change",
					}, ],
					lastName: [{
							required: true,
							message: this.translateTitle("请输入名字"),
							trigger: "change",
						},
						{
							required: true,
							validator: validateName,
							trigger: "change"
						}
					],
					firstName: [{
							required: true,
							message: this.translateTitle("请输入姓氏"),
							trigger: "change",
						},
						{
							required: true,
							validator: validateName,
							trigger: "change"
						}
					],
					middleName: [{
						required: false,
						validator: validateName,
						trigger: "change"
					}],
					nationality: [{
						required: true,
						message: this.translateTitle("请输入国籍"),
						trigger: "change",
					}, ],
					gender: [{
						required: true,
						message: this.translateTitle("请选择性别"),
						trigger: "change",
					}, ],
					idNumber: [{
						required: true,
						message: this.translateTitle("请输入证件号码"),
						trigger: "change",
					}, ],
					idType: [{
						required: true,
						message: this.translateTitle("请选择证件类型"),
						trigger: "change",
					}, ],
					dateOfBirth: [{
						required: true,
						message: this.translateTitle("请选择出生日期"),
						trigger: "change",
					}, ],

					type: [{
						type: "array",
						required: true,
						message: "请至少选择一个活动性质",
						trigger: "change",
					}, ],
					billingAddress: [{
							required: true,
							message: this.translateTitle("请输入地址"),
							trigger: "change",
						},
						{
							required: true,
							validator: validateAddress,
							trigger: "change"
						}
					],
					billingCity: [{
							required: true,
							message: this.translateTitle("请输入城市"),
							trigger: "change",
						},
						{
							required: true,
							validator: validateAddress,
							trigger: "change"
						}
					],
					billingCountry: [{
						required: true,
						message: this.translateTitle("请选择国家"),
						trigger: "change",
					}, ],
					billingZipCode: [{
						required: true,
						message: this.translateTitle("请输入邮政编码"),
						trigger: "change",
					}, ],
					deliveryAddress: [{
							required: true,
							message: this.translateTitle("请输入地址"),
							trigger: "change",
						},
						{
							required: true,
							validator: validateAddress,
							trigger: "change"
						}
					],
					deliveryCity: [{
							required: true,
							message: this.translateTitle("请输入城市"),
							trigger: "change",
						},
						{
							required: true,
							validator: validateAddress,
							trigger: "change"
						}
					],
					deliveryCountry: [{
						required: true,
						message: this.translateTitle("请选择国家"),
						trigger: "change",
					}, ],
					deliveryZipCode: [{
						required: true,
						message: this.translateTitle("请输入邮政编码"),
						trigger: "change",
					}, ],
				},

				isCheck: "1",
				ruleForm2: {
					email: "",
					amount: "",
					coinName: "",
					coinLink: "",
				},
				choiceCoin: {
					coinName: '',
					coinLogo: "",
				},
				rules1: {
					amount: [{
						required: true,
						message: this.translateTitle("请输入数量"),
						trigger: "change",
					}, ],
					email: [{
						required: true,
						message: this.translateTitle("请输入邮箱"),
						trigger: "change",
					}, ],
				},
				coinNameData: [],
				CoinLinkOption: [],
				btnloading: false,
			};
		},
		computed: {},
		created() {
			if (this.$route.query.type == '2') {
				this.type = 2;
			} else {
				this.type = 1;
			}
			this.getData();
			this.getUserinfoData();
			// this.getCoinName(1);
			// this.ruleForm.phone = this.userInfo.phone;
			// this.ruleForm.email = this.userInfo.email;
		},
		methods: {
			translateTitle,
			async getData() {
				let params = {
					endDate: this.endDate,
					startDate: this.startDate,
					page: this.page,
					pageSize: this.pageSize,
				};
				const loading = this.$loading({
					lock: true,
					text: '',
					spinner: '',
					background: 'rgba(255, 255, 255, 0.8)'
				});
				let res = await bankAccountStatement(params);
				if (res.code == 200) {
					this.tableData = res.data.records;
					this.total = res.data.total;
					loading.close()
				}

			},
			visibleEvent() {
				this.rechargeVisible = false;
				this.getData();
			},
			async getcryptoStatement() {
				let params = {
					endDate: this.endDate,
					startDate: this.startDate,
					page: this.page,
					pageSize: this.pageSize,
				};
				const loading = this.$loading({
					lock: true,
					text: '',
					spinner: '',
					background: 'rgba(255, 255, 255, 0.8)'
				});
				let res = await cryptoStatement(params);
				if (res.code == 200) {
					this.tableData = res.data.records;
					loading.close()
					this.total = res.data.total;
				}

			},
			async getCoinLink(val) {
				let res = await coinLink(val);
				this.CoinLinkOption = res.data;
			},
			changeCoinLink() {
				this.getBalanceCoin(2);
			},
			changeData() {
				this.startDate = this.value1[0];
				this.endDate = this.value1[1];
				this.getData();
			},
			async getUserinfoData(val) {
				let res = await getUserinfo();
				if (val == "1") {
					this.ruleForm = res.data;
				} else {
					this.userInfo = res.data;
					this.ruleForm = res.data;
				}
				// let res1 = await getIdTypes()
				// this.options1 = res1.data
				// this.getNationalData();
			},
			getBalanceCoin(type) {
				if (type == "1") {
					getBalanceCoinNam(this.ruleForm2.coinName).then((res) => {
						if (res.code == 200) {
							this.BalanceCoinNam = res.data.amount;
						} else {
							this.$message.error(this.translateTitle(res.msg));
						}

					});
				} else {
					if (this.ruleForm2.coinName && this.ruleForm2.coinLink) {
						let params = {
							coinName: this.ruleForm2.coinName,
							coinLink: this.ruleForm2.coinLink,
						};
						coinBalanceUSD(params).then((res) => {
							if (res.code == 200) {
								this.BalanceCoinNam = res.data.balance;
							} else {
								this.$message.error(this.translateTitle(res.msg));
							}

						});
					}
				}
			},
			async getCoinName(val) {
				let res = await coinNames();
				this.coinNameData = res.data;
				this.choiceCoin.coinName = this.coinNameData[0].coinName;
				this.choiceCoin.coinLogo = this.coinNameData[0].coinLogo;
				this.ruleForm2.coinName = this.coinNameData[0].coinName;
				this.coin
				if (val == "2") {
					this.getCoinLink(this.ruleForm2.coinName);
				}
				this.getBalanceCoin(val);
			},
			getTableData() {
				if (this.type == "1") {
					if (this.activeName == "first") {
						this.getData();
					} else {
						this.getcryptoStatement();
					}
				}
			},
			async getContact() {
				const data = {
					pageSize: this.contactQuery.pageSize,
					page: this.contactQuery.page,
				};
				let res = await contactPage(data);
				this.tableData = res.data.records;
				this.contactQuery.total = res.data.total;
			},
			async getNationalData() {
				let res = await getNational();
				this.options = res.data;
			},
			handleClick(tab, event) {
				this.page = 1;
				if (this.type == "1") {
					if (this.activeName == "first") {
						this.getData();
					} else {
						this.getcryptoStatement();
					}
				}
			},
			descclick(val) {
				this.type = 2;
				this.activeName = "first";
				this.getUserinfoData(1);
			},
			tableRowClassName({row, rowIndex}) {
			      if ((rowIndex+1) % 2  === 0) {
			        return '';
			      }else{
			        return 'success-row';
			      }
			    },
			changeType() {
				this.type = 2;
				this.activeName = "first";
				this.getNationalData();
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.loading1 = true
						addUserInfo(this.ruleForm).then((res) => {
							if (res.code == 200) {
								this.$message.success(this.translateTitle("操作成功"));
							} else {
								this.$message.error(this.translateTitle(res.msg));
							}
						}).finally(() => {
							this.loading1 = false
						});
						// alert("submit!");
					} else {
						return false;
					}
				});
			},
			onSure(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.btnloading = true
						addContact(this.ruleForm1).then((res) => {
							if (res.code == 200) {
								this.$message.success(this.translateTitle("操作成功"));
								this.getContact();
								this.visible = false;
								this.$refs["ruleForm1"].resetFields();
							} else {
								this.$message.error(this.translateTitle(res.msg));
							}
						}).finally(() => {
							this.btnloading = false
						});
					} else {
						return false;
					}
				});
			},
			//删除联系人
			deleteContact(row) {
				this.$confirm(
						this.translateTitle("删除后不可恢复"),
						this.translateTitle("提示"), {
							confirmButtonText: this.translateTitle("确定"),
							cancelButtonText: this.translateTitle("取消"),
							type: "",
							customClass: 'custom-message',
						}
					)
					.then(async () => {
						const res = await deleteDontact(row.id);
						if (res.code == 200) {
							this.$message.success(this.translateTitle("操作成功"));
							this.getContact();
						} else {
							this.$message.error(this.translateTitle(res.msg));
						}
					})
					.catch(() => {});
			},
			editAddress(val) {
				if (val == "0") {
					this.showAddress = true;
				} else {
					this.showAddress1 = true;
				}
			},
			//切换联系人列表
			handleperson() {
				this.type = 3;
				this.showAddress = false;
				this.showAddress1 = false;
				this.activeName = "first";
				this.getContact();
			},
			addPerson() {
				this.visible = true;
			},
			//关闭添加联系人
			onClose() {
				//   this.$refs[formName].resetFields();
				this.visible = false;
			},
			bankTransfer(row) {
				this.visible1 = true;
				if (row) {
					this.ruleForm2.email = row.email;
				} else {
					this.isCheck = 2;
				}
			},
			handcreateMccard() {
				this.loading = true
				createMccard().then((res) => {
					if (res.code == 200) {
						this.$message.success(this.translateTitle("操作成功"));
					} else {
						this.$message.error(this.translateTitle(res.msg));
					}
				}).finally(() => {
					this.loading = false
				});
			},
			changeCoinName(data) {
				let obj = this.coinNameData.find(item => item.coinName === data)
				this.choiceCoin.coinName = obj.coinName;
				this.choiceCoin.coinLogo = obj.coinLogo;
				this.ruleForm2.coinName = obj.coinName;
				this.ruleForm2.coinLink = "";
				this.getCoinLink(this.ruleForm2.coinName);
				this.getBalanceCoin(2);
			},
			checkType(val) {
				this.isCheck = val;
				if (val == "1") {
					userBankBalance().then((res) => {
						if (res.code == 200) {
							this.BalanceCoinNam = res.data.amount;
						} else {
							this.$message.error(this.translateTitle(res.msg));
						}

					});
				} else {
					this.getCoinName(2);
				}
			},
			onSure1(formName) {
				if (this.isCheck == "") {
					return this.$message.error(this.translateTitle("请先选择转账方式"));
				} else {
					let _resquest;
					let params;
					if (this.isCheck == "1") {
						_resquest = bank_transfer;
						params = {
							amount: this.ruleForm2.amount,
							email: this.ruleForm2.email,
						};
					} else {
						_resquest = coin_transfer;
						params = {
							amount: this.ruleForm2.amount,
							email: this.ruleForm2.email,
							coinName: this.ruleForm2.coinName,
							coinLink: this.ruleForm.coinLink,
						};
					}
					this.$refs[formName].validate((valid) => {
						if (valid) {
							this.btnloading = true
							_resquest(params).then((res) => {
								if (res.code == 200) {
									this.$message.success(this.translateTitle("操作成功"));
									// this.getContact()
									this.visible1 = false;
									this.$refs["ruleForm2"].resetFields();
								} else {
									this.$message.error(this.translateTitle(res.msg));
								}
							}).finally(() => {
								this.btnloading = false
							});
						} else {
							return false;
						}
					});
				}
			},
			onClose1() {
				this.$refs["ruleForm2"].resetFields();
				this.visible1 = false;
			},
			onCloseDetail() {
				this.visibleDetail = false
			},
			onCopy(text) {
				if (navigator.clipboard) {
					// clipboard api 复制
					navigator.clipboard.writeText(text);
				} else {
					const textarea = document.createElement("textarea");
					document.body.appendChild(textarea);
					// 隐藏此输入框
					textarea.style.position = "fixed";
					textarea.style.clip = "rect(0 0 0 0)";
					textarea.style.top = "10px";
					// 赋值
					textarea.value = text;
					// 选中
					textarea.select();
					// 复制
					document.execCommand("copy", true);
					// 移除输入框
					document.body.removeChild(textarea);
				}
				this.$message.success(this.translateTitle("内容已复制到剪贴板"));
			},
			handleClickBtn(type, row) {
				console.log(type,'123')
				if(type == 'recharge'){
					this.rechargeVisible = true;
				}else{
					this.visibleDetail = true
					this.detailData = row
				}
			}
		},
	};
</script>

<style lang="less" scoped>


	.title {
		font-size: 26px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: bold;
		color: #31363e;
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.setting-detail {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 22px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: bold;
		color: #31363e;
		margin-top: 30px;
		margin-bottom: 30px;

		.el-tag {
			margin-left: 15px;
		}
	}

	.table-detali {
		display: flex;
		justify-content: space-between;

		// align-items: center;
		.el-date-editor {
			margin-left: 10px;
			width: 300px;
		}

		img {
			width: 40px;
			vertical-align: middle;
		}
	}

	.succ {
		color: #3ad3b9;
	}

	.err {
		color: #d33a3a;
	}

	::v-deep {
		//   .el-form-item--feedback .el-input__validateIcon {
		//     /* display: inline-block; */
		// }

		.el-table {
			img {
				vertical-align: middle;
				margin-left: 20px;
			}
			.warning-row {
			    background-color: #fff
			}
			.success-row {
			    background: #F7F6F5;
			}
		}

		.el-tabs__nav-wrap::after {
			background-color: transparent;
			height: 0;
		}
		.el-tabs__active-bar {
			background-color: transparent;
			height: 3px;
		}

		.el-tabs__item {
			font-size: 18px;
			font-family: Microsoft YaHei-Bold, Microsoft YaHei;
			font-weight: 700;
			color: #31363E;
		}

		.el-tabs__item.is-active {
			font-size: 22px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: bold;
			color: #353a42;
		}
	}

	.el-col-17 {
		padding: 20px;
	}

	.el-col-7 {
		background: #f8fafb;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		padding: 20px;

		.line1 {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.left {
				width: 30px;
			}

			.right {
				display: flex;
				align-items: center;

				.text {
					text-align: right;
					font-family: Microsoft YaHei-Regular, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;

					.name1 {
						color: #31363e;
						margin-bottom: 5px;
					}

					.name2 {
						color: #b9bfca;
					}
				}

				.el-dropdown {
					display: flex;
					align-items: center;

					img {}
				}

				.img1 {
					width: 50px;
					margin: 0 5px 0 5px;
				}

				.img2 {
					width: 20px;
					height: 20px;
				}
			}
		}

		.line2 {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-weight: bold;
			margin: 35px 0 20px 0px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;

			.left {
				font-size: 22px;
				color: #31363e;
			}

			.right {
				font-size: 18px;
				color: #353a42;

				img {
					width: 20px;
					vertical-align: middle;
				}
			}
		}

		.line3 {
			font-size: 17px;
			margin: 20px 0px;

			.left {
				color: #b9bfca;
			}

			.right {
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 800;
				color: #31363e;
			}
		}

		.btn {
			width: 85%;
			height: 60px;
			background: #353a42;
			border-radius: 10px;
			color: #fff;
			text-align: center;
			line-height: 60px;
			font-size: 19px;
			margin: 20px auto;
		}

		.line4 {
			img {
				width: 60%;
				border-radius: 20px;
			}

			margin: 50px auto;
			text-align: center;
		}

		::v-deep {
			.el-select .el-input {
				width: 100px;
			}

			.input-with-select .el-input-group__append {
				background-color: #fff;
			}

			.el-input__inner,
			.el-input-group__append,
			.el-input-group__prepend {
				border-color: transparent;
				height: 45px;
			}
		}
	}

	.person-form {
		.edress-content {
			margin-left: 0px !important;
			margin-right: 0px !important;
			padding: 15px 30px;
			background: #f8fafb;
			border-radius: 10px;
			margin-top: 15px;
			margin-bottom: 20px;

			.editbtn {
				width: 200px;
				height: 40px;
				text-align: center;
				color: #fff;
				line-height: 40px;
				background: #cfe5ee;
				border-radius: 10px;
				margin: 20px;
			}
		}

		.edress-content1 {
			display: flex;
			justify-content: space-between;
			margin-bottom: 15px;
		}

		.el-input__inner {
			height: 45px;
			box-shadow: 3px 16px 13px 0px rgba(233, 238, 240, 0.28);
			border-radius: 17px;
			border: none;
		}

		.el-date-editor.el-input,
		.el-date-editor.el-input__inner,
		.el-select {
			width: 100%;
		}

		.btn {
			width: 262px;
			height: 45px;
			background: #353a42;
			border-radius: 10px;
			color: #fff;
			text-align: center;
			line-height: 45px;
			font-size: 14px;
		}
	}

	.change-form {
		background: #f2f7f9;
		padding: 30px;
		border-radius: 10px;

		::v-deep {
			.el-input__inner {
				height: 50px !important;
				line-height: 50px !important;
				border: none !important;
			}

			.el-form-item__label {
				font-size: 16px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: bold;
				color: #b9bfca;
			}

			.el-checkbox__input.is-checked .el-checkbox__inner,
			.el-checkbox__input.is-indeterminate .el-checkbox__inner {
				background-color: #06a43e !important;
				border-color: #06a43e !important;
			}

			.el-checkbox__input.is-checked+.el-checkbox__label {
				color: #000;
			}

			.el-checkbox__inner:hover {
				border-color: #000;
			}

			.input-with-select .el-input-group__append {
				background-color: #fff;
				border: none;
				width: 110px;
			}
		}
	}

	::v-deep {
		.custum-dialog {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;

			.img {
				text-align: center;
				background: #f2f7f9;
				border-radius: 10px;
				// padding: 30px 30px 20px 30px;
				padding: 20px 0;
				border: 2px solid #f2f7f9;
				width: 46%;

				p {
					font-size: 22px;
					font-family: Microsoft YaHei-Regular, Microsoft YaHei;
					font-weight: bold;
					color: #24282e;
				}

				// img {
				//   width: 60%;
				// }
			}

			.img.active {
				border: 2px solid #f6d655;
			}
		}
	}
	::v-deep {
			.btn-list {
				margin-top: 20px;
				flex: 1;
				text-align: right;

				.el-button--medium {
					width: 20%;
					height: 66px;
					background: #F7F6F5;
					border: 1px solid #F7F6F5;
					border-radius: 17px;
					font-size: 16px;
					font-family: Microsoft YaHei-Bold, Microsoft YaHei;
					font-weight: 400;
					color: #353a42;
				}
			}
		}
	.select-item {
		margin-left: 10px;
	}
</style>
<style>
	.dropdownPop img {
		width: 25px;
		vertical-align: middle;
		margin-right: 5px;
	}

	.el-form-item--feedback .el-input__validateIcon {
		display: none !important;
	}
</style>