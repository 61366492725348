<template>
  <div>
    <DialogPop
      width="700px"
      :title="translateTitle('充值到银行账户')"
      sureText="确定"
      :visible="visible"
      :closeBtn="false"
      :btnloading="btnloading"
      @onClose="onClose"
      @onSure="onSure('form')"
    >
      <div class="change-form">
        <el-form ref="form" :model="form" :rules="rules" label-position="top">
          <div>
            <el-form-item class="col2" :label="translateTitle('钱包选择')">
                <div class="flex">
                    <div class="flex1">
                  <div class="el-form-item__label">{{translateTitle('从')}}</div>
              <el-input :placeholder="translateTitle('请输入数量')" disabled v-model="type1"></el-input>
                </div>
                <div class="jiange" @click="changeType"><img width="20" src="../../../assets/imgs/ico_qiehuan@2x.png"></div>
                <div class="flex1">
                  <div  class="el-form-item__label">{{translateTitle('到')}}</div>
                <el-input v-model="type2" disabled :placeholder="translateTitle('请输入数量')" ></el-input>
                </div></div>
            </el-form-item>
         <div v-if="bankShow">
			 <el-form-item :label="translateTitle('从')" prop="coinName">
			 			<div style="display: flex;justify-content: space-between;">
			 				 <div style="width: 100%;">
			 					<el-input @mousewheel.native.prevent v-model="max"  @input="getNum" :placeholder="translateTitle('请输入划转数量')" > 
			 					<template slot="append">
			           <div class="disib common-color" @click="choiceMax">{{translateTitle('最大')}}</div>
			         </template>
			 				 </el-input>
			 				 <p>{{translateTitle('可用余额')}} {{choiceCoin.amount}}</p>
			 				 </div>
			 				 <el-select
			 				   class="svg-container"
			 				   clearable
			 				   style="width: 60%;"
			 				   v-model="form.coinName"
			 				   :placeholder="translateTitle('请选择')"
			 				   @change="changeCoinName(form.coinName)">
			 				   <template #prefix>
			 				     <span class="flex">
			 				       <img
			 				         :src="choiceCoin.coinLogo || require('@/assets/imgs/icon_usdt@2x.png')"
			 				         width="24"
			 				         height="24"
			 				       />
			 				     </span>
			 				   </template>
			 				   <el-option
			 				     v-for="item in option"
			 				     :key="item.id"
			 				     :label="item.coinName"
			 				     :value="item.id"
			 				   >
			 				     <div class="disal">
			 				       <img
			 				         :src="item.coinLogo"
			 				         width="24"
			 				         height="24"
			 				       />
			 				       <span class="select-item">{{ item.coinName }}</span>
			 				     </div>
			 				   </el-option>
			 				 </el-select>
			 			</div>
			 </el-form-item>
			   <el-form-item style="margin-bottom: 10px;" :label="translateTitle('到')">
			     <el-input clearable readonly
			    @input="getCoinNum"  @mousewheel.native.prevent v-model="ustNum" >
			       <template slot="append">
			         <div style="color: black;">
			 					  USD
			 				  </div>
			       </template>
			     </el-input>
			   </el-form-item>
			   <div class="mb15">
			     <span class="account-balance">{{translateTitle('可用余额')}}</span>
			     <span class="account-num">&nbsp;{{userbalance}}</span>
			   </div>
			   <div v-if="form.amount" class="mb15 account-num1">
			     <span>{{translateTitle('矿工费')}}</span>
			     <span>&nbsp;{{ transferAmount }} {{type2 == this.translateTitle('账户')?form.coinName:'USDT'}}</span>
			   </div>
		 </div>
		 <div v-else>
		 
		 	  <el-form-item style="margin-bottom: 10px;" :label="translateTitle('从')">
		 	    <el-input :placeholder="translateTitle('请输入划转数量')" clearable
		 	   @input="getCoinNumChange" type="number"  @mousewheel.native.prevent v-model="ustNumchange">
		 	      <template slot="append">
		 	        <div style="color: black;">
		 						  USD
		 					  </div>
		 	      </template>
		 	    </el-input>
				 <p>{{translateTitle('可用余额')}} {{userbalance}}</p>
		 	  </el-form-item>
			  <el-form-item :label="translateTitle('到')" prop="coinName">
			  			<div style="display: flex;justify-content: space-between;">
			  				 <div style="width: 100%;">
			  					<el-input v-model="maxchange"  @input="getNumChange" @mousewheel.native.prevent readonly> 
			  					<template slot="append">
			            <div style="display: none;" class="disib common-color" @click="choiceMaxChange">{{translateTitle('最大')}}</div>
			          </template>
			  				 </el-input>
			  				 <p>{{translateTitle('可用余额')}} {{choiceCoin.amount}}</p>
			  				 </div>
			  				 <el-select
			  				   class="svg-container"
			  				   clearable
			  				   style="width: 60%;"
			  				   v-model="form.coinName"
			  				   :placeholder="translateTitle('请选择')"
			  				   @change="changeCoinName(form.coinName)">
			  				   <template #prefix>
			  				     <span class="flex">
			  				       <img
			  				         :src="choiceCoin.coinLogo || require('@/assets/imgs/icon_usdt@2x.png')"
			  				         width="24"
			  				         height="24"
			  				       />
			  				     </span>
			  				   </template>
			  				   <el-option
			  				     v-for="item in option"
			  				     :key="item.id"
			  				     :label="item.coinName"
			  				     :value="item.id"
			  				   >
			  				     <div class="disal">
			  				       <img
			  				         :src="item.coinLogo"
			  				         width="24"
			  				         height="24"
			  				       />
			  				       <span class="select-item">{{ item.coinName }}</span>
			  				     </div>
			  				   </el-option>
			  				 </el-select>
			  			</div>
			  </el-form-item>
		 	  <div v-if="form.amount" class="mb15 account-num1">
		 	    <span>{{translateTitle('矿工费')}}</span>
		 	    <span>&nbsp;{{ transferAmount }} {{type2 == this.translateTitle('账户')?form.coinName:'USDT'}}</span>
		 	  </div>
		 </div>
          </div>
        </el-form>
      </div>
    </DialogPop>
  </div>
</template>

<script>
import { sysCoins, coinLink, coinBalanceUSD, getTransferRate, userBankBalance, calculateCharge,rechargeCoins} from "@/api/index.js";
import { coinSend, coinBalance, reciveCoin, recharge } from "@/api/wallet.js";
import { translateTitle } from "@/utils/i18n";
import {multiply,subtr,division} from '@/utils/date'
import VueQr from "vue-qr";
import { number } from 'echarts/core';
export default {
  name: "sendDiaolg",
  model: {
    prop: "visible",
    event: "visibleEvent",
  },
  components: { VueQr },
  props: {
    // 显示/隐藏
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const validateAmount = (rule, value, callback) => {
      const num= /(^[0-9]\d*(\.\d{1,100})?$)|(^0(\.\d{1,100})?$)/;
      if (!num.test(value)) {
        callback(new Error(this.translateTitle("请输入正确格式")))
      }else{
        callback()
      }
    }
    return {
      option: [],
      cities: [],
      balance: "",
      codeText: "",
      choiceCoin: {
        coinName: '',
		amount:'',
        coinLogo: "",
		toUsdtRate:''
      },
      lgoImg: '',
      type1: '',
      type2:'',
      form: {
        amount: "",
        coinName: "USDT",
        coinNetWork: "",
        transferWalletAddress: "",
      },
	  max:'',
	  ustNum:'',
	  ustNumchange:'',
	  maxchange:'',
	  bankShow:false,
      rules: {
        amount: [
          {
            required: true,
            message: this.translateTitle("请输入提币数量"),
            trigger: "change",
          },
          { required: true, validator: validateAmount, trigger: "change" }
        ],
        coinName: [
          {
            required: true,
            message: this.translateTitle("请选择币种"),
            trigger: "change",
          },
        ],
        transferWalletAddress: [
          {
            required: true,
            message: this.translateTitle("请输入提币地址"),
            trigger: "change",
          },
        ],
        idNumber: [
          {
            required: true,
            message: this.translateTitle("请输入证件号码"),
            trigger: "change",
          },
        ],
        coinNetWork: [
          {
            required: true,
            message: this.translateTitle("请选择提币网络"),
            trigger: "change",
          },
        ],
        idExpire: [
          {
            required: true,
            message: this.translateTitle("请选择过期时间"),
            trigger: "change",
          },
        ],
      },
      activeName: "first",
      btnloading: false,
      transferRate: '0',
      transferAmount: '',
      globalLoading: null,
	  isUserate:'',
	  rate:'',
	  userbalance:''
    };
  },
  created() {
    this.getCoinName();
	this.getRechargeCoins()
    this.type1 = this.translateTitle('账户')
    this.type2 = this.translateTitle('加密账号')
	let data = {
		coinName: 'USDT_BSC',
		coinLink: 'BSC_USDT',
	}
	this.getcoinBalance(data)
	this.getBanlce()
	 this.getUserBankBalance()
    // this.activeName = this.sendType
    // this.getcoinBalance(this.sendData)
    // this.form.coinName = this.sendData.coinName
    // this.form.coinNetWork = this.sendData.coinLink
  },
  methods: {
    multiply,
    subtr,
    translateTitle,
    division,
    changeAmount1(val) {
     if (val == '') { this.transferAmount = 0; } else {
        //  this.transferAmount = this.multiply(this.transferRate,params);
       this.getTransferAmount(val)
          }
    },
	async getRechargeCoins(){
		let data = {
			type:0
		}
	  	let res = await rechargeCoins(data);
		  this.form.coinId = res.data[0].id
		  console.log(res,'res')
	},
	async getBanlce(val){
		  let params = {
		    coinName: 'USDT_BSC',
		    coinLink: 'BSC_USDT',
		     inputAmount: val || 0,
		     type: this.type2 == this.translateTitle('账户')? '4': '5'
		   }
		   await calculateCharge(params).then(res => {
		      this.transferAmount = res.data.charge
			  console.log(res,'this.transferAmount')
			  this.isUserate = res.data.useRate
			  this.rate = res.data.rate
		   })
	},
	async getNum(e){
		if(this.max==this.e){
			return
		}
		if(this.max<=0){
			// this.$message.error(this.translateTitle('请输入大于0的数'));
			this.ustNum = ''
			return
		}
		await this.getBanlce(this.max)
		this.ustNum = (this.max - this.transferAmount)*this.choiceCoin.toUsdtRate
		if(this.max==''){
			this.ustNum = ''
		}
	},
	async getCoinNum(){
		if(this.ustNum==this.e){
			return
		}
		if(this.ustNum<=0){
			// this.$message.error(this.translateTitle('请输入大于0的数'));
			this.max = ''
			return
		}
		if(this.isUserate==false){
			await this.getBanlce(this.max)
			this.max = Number(this.transferAmount)+ (this.ustNum/this.choiceCoin.toUsdtRate)
		}else{
			await this.getBanlce(this.max)
			this.max = this.ustNum/this.choiceCoin.toUsdtRate/(1 - this.rate)
			console.log(this.ustNum,this.choiceCoin.toUsdtRate,this.rate,'121')
		}
		if(this.ustNum==''){
			this.max = ''
		}
	},
	// 切换输入
	async getCoinNumChange(){
		if(this.ustNumchange==this.e){
			return
		}
		if(this.ustNumchange<=0){
			// this.$message.error(this.translateTitle('请输入大于0的数'));
			this.maxchange = ''
			return
		}
		await this.getBanlce(this.ustNumchange)
		this.maxchange = (this.ustNumchange - this.transferAmount)/this.choiceCoin.toUsdtRate
		console.log(this.maxchange,this.transferAmount,this.choiceCoin.toUsdtRate,'this.choiceCoin.toUsdtRate')
		if(this.ustNumchange==''){
			this.maxchange = ''
		}
	},
	async getNumChange(){
    console.log(222)
		if(this.maxchange==e){
			return
		}
		if(this.maxchange<=0){
			// this.$message.error(this.translateTitle('请输入大于0的数'));
			this.ustNumchange = ''
			return
		}
		if(this.isUserate==false){
			await this.getBanlce(this.maxchange)
			this.ustNumchange = Number(this.transferAmount) + (this.maxchange*this.choiceCoin.toUsdtRate)
		}else{
			await this.getBanlce(this.maxchange)
			this.ustNumchange = Number(this.maxchange) * Number(this.choiceCoin.toUsdtRate)/(Number(1) - Number(this.rate))
			console.log(this.maxchang,this.choiceCoin.toUsdtRate,(1 - this.rate),'121')
		}
		if(this.maxchang==''){
			this.ustNumchange = ''
		}
	},
    changeCoinName(data) {
      let obj  = this.option.find(item => item.id === data)
      console.log(obj,'changeCoinName')

      this.choiceCoin.coinName = obj.coinName;
      this.choiceCoin.coinLogo = obj.coinLogo;
	    this.choiceCoin.amount = obj.amount;
	    this.choiceCoin.toUsdtRate = obj.toUsdtRate;
      this.form.coinLink = obj.coinLink;
      this.form.coinName = obj.coinName
      this.form.coinId = obj.id
      // this.getcoinBalance(obj);
      if(this.type2 == this.translateTitle('账户')) {
        this.getcoinBalance(obj)
      } else {
        this.getUserBankBalance(obj)
      }
      this.getTransferAmount(this.form.amount)
    },
    getTransferAmount(val) {
      let params = {
         coinLink: this.form.coinLink,
         coinName: this.form.coinName,
         inputAmount: val || 0,
         type: this.type2 == this.translateTitle('账户')? '4': '5'
       }
       calculateCharge(params).then(res => {
          this.transferAmount = res.data.charge
       })
    },
    async getCoinName() {
      let res = await sysCoins();
      this.option = res.data;
    },
	
    // changeCoinLink() {
    //   this.getcoinBalance();
    // },
    getcoinBalance(val) {
      let params = {
        coinName: val.coinName,
        coinLink: val.coinLink || 'USDT_BSC',
      };
      console.log("请求参数：",params);
      coinBalanceUSD(params).then((res) => {
        console.log("响应结果",res);
        if (res.code == 200) {
          this.balance = res.data.balance;
		      this.choiceCoin.amount = res.data.balance
		      this.choiceCoin.toUsdtRate=res.data.to_usdt_rate
		    console.log(this.choiceCoin.toUsdtRate,res,'this.choiceCoin.toUsdtRate')
        } else {
          this.$message.error(this.translateTitle(res.msg));
        }
        
      });
      // let params1 = {
      //   type: '4'
      // }
      // getTransferRate({...params, ...params1}).then(res => {
      //   this.transferRate = res.data.rate
      // })
      // coinBalanceUSD(params).then((res) => {
      //   if(res.code == 200) {
      //     this.balance = res.data.balance;
      //   } else {
      //     this.$message.error(this.translateTitle(res.msg));
      //   }
      // });
    },
    getUserBankBalance(val) {
      userBankBalance().then((res) => {
        if(res.code == 200) {
          this.balance = Number(res.data.amount).toFixed(2);
		      this.userbalance  = res.data.amount
          // let params1 = {
          //   coinName: val.coinName,
          //   coinLink: val.coinLink,
          //   type:'5'
          // }
          // getTransferRate(params1).then(res => {
          //   this.transferRate = res.data.rate
          // })
        } else {
          this.$message.error(this.translateTitle(res.msg));
        }
      });
    },
    onSure(formName) {
		if(this.max!=''&&this.max<=0){
			this.$message.error(this.translateTitle('请输入大于0的数'));
			return
		}else if(this.ustNumchange!=''&&this.ustNumchange<=0){
			this.$message.error(this.translateTitle('请输入大于0的数'));
			return
		}
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            amount: this.max || this.ustNumchange,
            coinId: this.form.coinId,
            type: this.type2 == this.translateTitle('账户')? '0': '1'
          }
          // this.btnloading = true
          this.globalLoading = this.$loading({
              lock: true,
              text: '',
              spinner: '',
              background: 'rgba(255, 255, 255, 0.8)'
            });
          if (this.activeName == "first") {
            recharge(params).then((res) => {
              if (res.code == 200) {
                this.$message.success(this.translateTitle("操作成功"));
                // this.getData()
                this.visible = true;
                this.$refs["form"].resetFields();
                this.$emit("visibleEvent", false);
              } else {
                this.$message.error(this.translateTitle(res.msg));
              }
            }).finally(() => {
              // this.btnloading = false
              this.globalLoading.close()
            });
          } else {
            // let params = {
            //   coinLink: this.sendData.coinLink,
            //   coinName: this.sendData.coinName,
            // };
            // reciveCoin(params).then((res) => {
            //   if(res.code == 200) {
            //     this.codeText = res.data.walletAddress;
            //   } else {
            //     this.$message.error(this.translateTitle(res.msg));
            //   }
            // }).finally(() => {
            //   this.btnloading = false
            // });
          }
        } else {
          return false;
        }
      });
    },
	async choiceMax(){
			await this.getBanlce(this.choiceCoin.amount)
			this.ustNum = (this.choiceCoin.amount - this.transferAmount)*this.choiceCoin.toUsdtRate
			this.max = this.choiceCoin.amount
	},
	async choiceMaxChange(){
		if(this.isUserate==false){
			await this.getBanlce(this.maxchange)
			this.ustNumchange = Number(this.transferAmount) + (this.maxchange*this.choiceCoin.toUsdtRate)
		}else{
			await this.getBanlce(this.maxchange)
			this.ustNumchange = this.maxchang*this.choiceCoin.toUsdtRate/(1 - this.rate)
			console.log(this.maxchange,this.choiceCoin.toUsdtRate,this.rate,'121')
		}
		this.maxchange = this.choiceCoin.amount
	},
    // 取消
    onClose() {
      // this.visible = false;
      this.$emit("visibleEvent", false);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    choiceAll() {
      this.form.amount = this.balance;
      // this.transferAmount = this.multiply(this.transferRate,this.balance);
      this.getTransferAmount(this.form.amount)
    },
    onCopy(text) {
      if (navigator.clipboard) {
        // clipboard api 复制
        navigator.clipboard.writeText(text);
      } else {
        const textarea = document.createElement("textarea");
        document.body.appendChild(textarea);
        // 隐藏此输入框
        textarea.style.position = "fixed";
        textarea.style.clip = "rect(0 0 0 0)";
        textarea.style.top = "10px";
        // 赋值
        textarea.value = text;
        // 选中
        textarea.select();
        // 复制
        document.execCommand("copy", true);
        // 移除输入框
        document.body.removeChild(textarea);
      }
      this.$message.success(this.translateTitle("内容已复制到剪贴板"));
    },
    changeType() {
      this.type1 = this.type1 == this.translateTitle('加密账号') ? this.translateTitle('账户') : this.translateTitle('加密账号')
      this.type2 = this.type2 == this.translateTitle('加密账号') ? this.translateTitle('账户') : this.translateTitle('加密账号')
	  console.log(this.type1,this.type2,'this.type1')
	  if(this.type1!='银行账号'){
		  this.bankShow = true
	  }else{
		  this.bankShow = false
	  }
      this.form.amount = 0
      this.getTransferAmount(this.form.amount)
      if(this.type2 == this.translateTitle('账户')) {
        this.getcoinBalance(this.form)
      } else {
        this.getUserBankBalance(this.form)
      }
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep {
  .el-tabs__item {
    font-size: 24px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: bold;
    color: #6F7D8D;
  }
  .el-tabs__item.is-active {
    color: #31363e;
  }
  .el-tabs__nav-wrap::after {
    height: 0;
  }
  .el-tabs__active-bar {
    width: 76px;
    height: 4px;
    background: #165BA4;
  }

  .el-dialog__body {
    padding: 10px 20px;
  }
  .el-input-group__append {
    background-color: #fff;
    border: none;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #b9bfca;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      height: 46px;
      width: 1px;
      background-color: #eee;
      top: 12px;
      left: 0;
    }
  }
}
.change-form {
//   .el-form-item.col2 .el-input{
//     width: 46%;
//   }
   .el-form-item.col2 .flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
  .el-form-item.col2 .jiange {
    padding: 0 20px;
    line-height: 45px;
  }
  ::v-deep { .el-input-group__append::after {
    background-color: transparent !important;
  }
  }
  .account-balance {
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #31363E;
    margin-right: 20px;
  }
  .account-num {
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #31363E;
  }
  .account-num1 {
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: rgba(49,54,62,0.6);
  }
  .google-code {
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
  }
  .google-address {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #b9bfca;
  }
  .two-between {
    background-color: #fff;
    border-radius: 8px;
    margin-top: 15px;
    padding: 15px;
  }
  .google-code-num {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: bold;
    color: #31363e;
  }
}
.select-item {
  // font-size: 18px;
  // font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  // font-weight: 400;
  // color: #31363e;
  margin-left: 10px;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
-webkit-appearance: none !important;
}
::v-deep input[type='number'] {
-moz-appearance: textfield !important;
}
</style>
